import request from '@/utils/request'

// 查询字典数据列表
export function listData(query) {
  return request({
    url: '/gw/dict_data/list',
    method: 'get',
    params: query
  })
}

// 查询字典数据详细
export function getData(dictCode) {
  return request({
    url: '/gw/dict_data/' + dictCode,
    method: 'get'
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/gw/dict_data/type/' + dictType,
    method: 'get'
  })
}