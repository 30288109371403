<template>
  <div class="upload-file">
    <el-upload
      multiple
      :action="uploadFileUrl"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :show-file-list="false"
      :headers="headers"
      class="upload-file-uploader"
      ref="fileUpload"
    >
      <!-- 上传按钮 -->
      <el-button type="primary">选取文件</el-button>
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip">
      请上传
      <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
      <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template>
      的文件
    </div>
    <!-- 文件列表 -->
    <el-row>
        <el-tooltip class="box-item" v-for="(file, index) in fileList" effect="light" :content="file.name" placement="top-start">
          <el-col :span="5" :offset="1">
            <div class="file-upload-fCss">
              <a :href="file.url" class="el-icon-document"><Document style="height: 4em;width: 3.5em;color: #fff;border-bottom-left-radius: 5px;border-top-left-radius: 5px;background: #73a5ed"></Document></a>
              <CloseBold @click="handleDelete(index)" style="color: #fff;height: 0.8em;width: 0.8em;cursor: pointer;margin-left: 0px"></CloseBold>
            </div>
          </el-col>
        </el-tooltip>
    </el-row>
  </div>
</template>

<script setup>
import { getToken } from "@/utils/auth";
import {getCurrentInstance,ref,computed,watch} from 'vue';
import {apiUrl, elMessgae} from "../../css/Sugar";
import { ElLoading } from 'element-plus'

const props = defineProps({
  modelValue: [String, Object, Array],
  // 数量限制
  limit: {
    type: Number,
    default: 5,
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 5,
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ["doc", "xls", "ppt", "txt", "pdf"],
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  }
});

const { proxy } = getCurrentInstance();
const emit = defineEmits();
const number = ref(0);
const uploadList = ref([]);
const baseUrl = ref("/dev-api");
const uploadFileUrl = ref(apiUrl+"/gw/common/upload"); // 上传文件服务器地址
const headers = ref({ Authorization: "Bearer " + getToken() });
const fileList = ref([]);
const fullscreenLoading = ref(false);
const loading = ref(null);
const showTip = computed(
  () => props.isShowTip && (props.fileType || props.fileSize)
);

function linkClick(val) {
    console.dir(val);
}

watch(() => props.modelValue, val => {
  if (val) {
    let temp = 1;
    // 首先将值转为数组
    const list = Array.isArray(val) ? val : props.modelValue.split(',');
    // 然后将数组转为对象数组
    fileList.value = list.map(item => {
      if (typeof item === "string") {
        item = { name: item, url: item };
      }
      item.uid = item.uid || new Date().getTime() + temp++;
      return item;
    });
  } else {
    fileList.value = [];
    return [];
  }
},{ deep: true, immediate: true });

// 上传前校检格式和大小
function handleBeforeUpload(file) {
  // 校检文件类型
  if (props.fileType.length) {
    let fileExtension = "";
    if (file.name.lastIndexOf(".") > -1) {
      fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
    }
    const isTypeOk = props.fileType.some((type) => {
      if (file.type.indexOf(type) > -1) return true;
      if (fileExtension && fileExtension.indexOf(type) > -1) return true;
      return false;
    });
    if (!isTypeOk) {
        elMessgae("文件格式不正确, 请上传doc/xls/ppt/txt/pdf格式文件!","error");
      return false;
    }
  }
  // 校检文件大小
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize;
    if (!isLt) {
        elMessgae(`上传文件大小不能超过 ${props.fileSize} MB!`,"error");
      return false;
    }
  }
    fullscreenLoading.value = true;
    loading.value = ElLoading.service({
        lock: true,
        text: '正在上传文件，请稍候...',
        background: 'rgba(0, 0, 0, 0.7)',
    })
  number.value++;
  return true;
}

// 文件个数超出
function handleExceed() {
    elMessgae(`上传文件数量不能超过 ${props.limit} 个!`,"error");
}

// 上传失败
function handleUploadError(err) {
    elMessgae(`上传文件失败`,"error");
}

// 上传成功回调
function handleUploadSuccess(res, file) {
  if (res.code === 200) {
    uploadList.value.push({ name: res.data.originalFilenames, url: res.data.urls });
    uploadedSuccessfully();
  } else {
    number.value--;
    proxy.$refs.fileUpload.handleRemove(file);
    uploadedSuccessfully();
  }
}

// 删除文件
function handleDelete(index) {
  fileList.value.splice(index, 1);
  emit("update:modelValue", listToString(fileList.value));
}

// 上传结束处理
function uploadedSuccessfully() {
  if (number.value > 0 && uploadList.value.length === number.value) {
    fileList.value = fileList.value.filter(f => f.url !== undefined).concat(uploadList.value);
    uploadList.value = [];
    number.value = 0;
      elMessgae("上传成功","success");
      loading.value.close();
    emit("update:modelValue", fileList.value);
  }
}

// 获取文件名称
function getFileName(name) {
  if (name.lastIndexOf("/") > -1) {
    return name.slice(name.lastIndexOf("/") + 1);
  } else {
    return "";
  }
}

// 对象转成指定字符串分隔
function listToString(list, separator) {
  let strs = "";
  separator = separator || ",";
  for (let i in list) {
    if (list[i].url) {
      strs += list[i].url + separator;
    }
  }
  return strs != '' ? strs.substr(0, strs.length - 1) : '';
}
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}

.file-upload-fCss{
  z-index:999;
  width: 90%;
  height: 84%;
  border: 1px #fff dashed;
  margin-right: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

  .file-upload-fCss:hover{
    border: 1px #97cff9 dashed;
    background: #73a5ed;
  }
</style>
