import { createApp } from 'vue'

import Cookies from 'js-cookie'
import router from './router'
import ElementPlus from 'element-plus'
import '@/static/font.css'
import 'element-plus/dist/index.css'
// import SvgIcon from '@/components/SvgIcon'
import elementIcons from '@/components/SvgIcon/svgicon'
import App from './App.vue'
import locale from 'element-plus/dist/locale/zh-cn.mjs'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import * as echarts from 'echarts'
import 'echarts-wordcloud';
import { useDict } from '@/utils/dict'
import pinia from '@/store/index'
import DictTag from '@/components/DictTag'
import { download } from '@/utils/request'
import FileUpload from "@/components/FileUpload"
import 'vue3-video-play/dist/style.css'
import Print from 'vue3-print-nb'
import '@/utils/rem'

NProgress.configure({
    easing: 'ease',
    speed: 1000,
    showSpinner: false,
    trickleSpeed: 500,
    minimum: 0.3
})

router.beforeEach((to, from, next) => {
    // window.onload = function() {
    //     window.scrollTo(0, 0);
    // };
    NProgress.start();
    next();
});

//路由跳转结束
router.afterEach(() => {
    NProgress.done()
})

window.router = router;

const app = createApp(App)
app.use(router)
app.use(pinia)
app.use(elementIcons)
app.use(Print )
// app.component('svg-icon', SvgIcon)
app.component('DictTag', DictTag)
app.component('FileUpload', FileUpload)

app.config.errorHandler = (err) => {
    console.log(err);
};

app.config.globalProperties.useDict = useDict;
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.download = download;

app.use(ElementPlus, {
    // 支持 large、default、small
    locale,
    size: Cookies.get('size') || 'default'
})

app.mount('#app')
