<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<script setup>
  import {onMounted} from 'vue';
import "./css/index.css"

const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

onMounted(() => {
    var _hmt = _hmt || [];
    (function() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?1baafb016bc5e3d033c3ec422ad7551d";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
        constructor(callback) {
            callback = debounce(callback, 50);
            super(callback);
        }
    }
})
// import { ref,watch,nextTick,reactive,onMounted  } from 'vue'
//
// const appref = ref(null);
// const isMobile = ref(false);
//
// //屏幕自适应
// const Specifications = reactive({
//     //定义的宽高比例，初始为1
//     ww: 1,
//     wh: 1,
//     //根据class="home"里面定义的宽高进行作为初始宽高进行计算
//     //！自定义内容！
//     width: 1920,
//     height: 919,
//     //！自定义内容！
// })
// //将方法定义在响应式对象里面使得代码整洁一些
// const method = reactive({
//     getScale() {
//         // 获取到缩放比例
//         Specifications.wh = window.innerHeight / Specifications.height
//         Specifications.ww = window.innerWidth / Specifications.width
//         //将比例设置进dom元素中
//         appref.value.style.setProperty('--scaleX', Specifications.ww)
//         appref.value.style.setProperty('--scaleY', Specifications.wh)
//     },
// })
// onMounted(() => {
//     //调用getScale()方法
//     handleResize();
//     if(isMobile.value){
//         appref.value = document.getElementsByClassName('appMain')[0];
//         method.getScale()
//     }
// })
// var EventUtil = {
//     addHandler: function (element, type, handler) {
//         if (element.addEventListener) {
//             element.addEventListener(type, handler, false);
//         } else if (element.attachEvent) {
//             element.attachEvent("on" + type, handler);
//         } else {
//             element["on" + type] = handler;
//         }
//     }
// };
// EventUtil.addHandler(window, "resize", function () {
//     appref.value = document.getElementsByClassName('appMain')[0];
//     method.getScale()
// });
//自适应结束
</script>

<style>
  body{
    font-family: Helvetica Neue, Helvetica,PF, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
    '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
    /*overflow-y: hidden;*/
  }
  /*.appMain{*/
    /*width: 1920px;*/
    /*height: 919px;*/
    /*transform: scale(var(--scaleX), var(--scaleY));*/
    /*transform-origin: 0 0;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*transition: 0.1s;*/
  /*}*/
  /*545c64 2b323a 191b23*/
  #app .sidebar-container a{
    background: #363e46;
  }
  #app .sidebar-container .scrollbar-wrapper{
    background: #363e46;
  }

  #app .sidebar-container .menu-title{
    color: #ededed;
  }

  #app .sidebar-container .svg-icon {
    margin-right: 16px;
    color: #ededed;
  }

  #app .sidebar-container .el-icon svg{
    color: #ededed;
  }

  #app .sidebar-container .theme-dark .nest-menu .el-sub-menu > .el-sub-menu__title, #app .sidebar-container .theme-dark .el-sub-menu .el-menu-item{
    background: #2b323a !important;
  }

  #app .sidebar-container .theme-dark .el-sub-menu .el-menu-item:hover{
    background: #191b23 !important;
  }

  #app .sidebar-container .el-menu-item:hover{
    background: #2b323a !important;
  }

  #nprogress .bar {
    /*background: rgba(217,172,167) !important;*/
    height: 3px !important;
  }

  body{
    height: calc( 100vh - 8px );
  }

  .el-radio{
    margin-right: 5px;
  }
  .el-radio__label{
    font-size: 13px;
  }

  .el-radio__inner{
    width: 12px;
    height: 12px;
  }


</style>
