import { createWebHistory, createRouter } from 'vue-router'


export const constantRoutes = [
    {
        path: '/',
        component: () =>
            import ('@/views/home'),
        hidden: true,
        children:[
            // {
            //     path: '/homePage',
            //     name:'first',
            //     component: () =>
            //         import ('@/views/assis/index'),
            //     hidden: true,
            //     meta: {
            //         keepAlive: true // 缓存该页面
            //     },
            //     children:[
            //
            //     ]
            // },
        ]
    },
    {
        path: '/personal/index',
        component: () =>
            import ('@/views/personal/index'),
        hidden: true,
        children:[
            {
                path: '/personal/manager',
                name:'manager',
                component: () =>
                    import ('@/views/personal/manager'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/personal/order',
                name:'order',
                component: () =>
                    import ('@/views/personal/order/index'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/personal/order/add',
                name:'orderAdd',
                component: () =>
                    import ('@/views/personal/order/add'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
                children:[
                    {
                        path: '/personal/order/addUpload',
                        name:'orderAddUpload',
                        component: () =>
                            import ('@/views/personal/order/addUpload'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                    {
                        path: '/personal/order/addHand',
                        name:'orderAddHand',
                        component: () =>
                            import ('@/views/personal/order/addHand'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                ]
            },
            {
                path: '/personal/order/editHand',
                name:'orderEditHand',
                component: () =>
                    import ('@/views/personal/order/editHand'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/personal/dataquery',
                name:'dataquery',
                component: () =>
                    import ('@/views/personal/dataquery'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/personal/api',
                name:'api',
                component: () =>
                    import ('@/views/personal/api'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/personal/finance',
                name:'finance',
                component: () =>
                    import ('@/views/personal/finance'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
                children:[
                    {
                        path: '/personal/finance/index',
                        name:'financeIndex',
                        component: () =>
                            import ('@/views/personal/finance/index'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                    {
                        path: '/personal/finance/check',
                        name:'financeCheck',
                        component: () =>
                            import ('@/views/personal/finance/check'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                ]
            },

            {
                path: '/personal/user',
                name:'user',
                component: () =>
                    import ('@/views/personal/user'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
                children:[
                    {
                        path: '/personal/user/index',
                        name:'userIndex',
                        component: () =>
                            import ('@/views/personal/user/index'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                    {
                        path: '/personal/user/add',
                        name:'userAdd',
                        component: () =>
                            import ('@/views/personal/user/userAdd'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                    {
                        path: '/personal/user/editPwd',
                        name:'editPwd',
                        component: () =>
                            import ('@/views/personal/user/editPwd'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                    {
                        path: '/personal/role/index',
                        name:'roleIndex',
                        component: () =>
                            import ('@/views/personal/user/role'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                    {
                        path: '/personal/role/add',
                        name:'roleAdd',
                        component: () =>
                            import ('@/views/personal/user/roleAdd'),
                        hidden: true,
                        meta: {
                            keepAlive: true // 缓存该页面
                        },
                    },
                ]
            },
        ]
    },

    {
        path: '/home/main',
        component: () =>
            import ('@/views/home/channel/index'),
        hidden: true,
        children: [
            {
                path: '/home/channel',
                component: () =>
                    import ('@/views/home/channel/channel'),
                hidden: true,
            },
            {
                path: '/home/channelDetail',
                component: () =>
                    import ('@/views/home/channel/channelDetail'),
                hidden: true,
            },
            {
                path: '/home/trends',
                component: () =>
                    import ('@/views/home/channel/trends'),
                hidden: true,
            },
            {
                path: '/home/trendsDetail',
                component: () =>
                    import ('@/views/home/channel/trendsDetail'),
                hidden: true,
            },
            {
                path: '/home/contact',
                component: () =>
                    import ('@/views/home/channel/contact.vue'),
                hidden: true,
            },
            {
                path: '/home/dataquery',
                name: "channel-dataquery",
                component: () =>
                    import ('@/views/home/channel/dataquery'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/home/fba',
                name: "channel-fba",
                component: () =>
                    import ('@/views/home/channel/fba'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/home/ldp',
                name: "channel-ldp",
                component: () =>
                    import ('@/views/home/channel/ldp'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/home/enquiry',
                name: "channel-enquiry",
                component: () =>
                    import ('@/views/home/channel/enquiry'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
            {
                path: '/home/enquiry2',
                name: "channel-enquiry2",
                component: () =>
                    import ('@/views/home/channel/enquiry2'),
                hidden: true,
                meta: {
                    keepAlive: true // 缓存该页面
                },
            },
        ]
    },

    {
        path: '/home/register',
        component: () =>
            import ('@/views/home/register/index'),
        hidden: true
    },
    {
        path: '/home/register/manager',
        component: () =>
            import ('@/views/home/register/manager'),
        hidden: true
    },
    {
        path: '/home/register/finish',
        component: () =>
            import ('@/views/home/register/finish'),
        hidden: true
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: constantRoutes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
});

export default router;