import request from '@/utils/request'

export function captchaSend(data) {
    return request({
        url: '/gw/captcha/send',
        method: 'post',
        data: data
    })
}

export function ocrCustomerBusinessLicense(query) {
    return request({
        url: '/gw/ocr/businessLicense',
        method: 'get',
        params: query
    })
}

export function registerUser(data) {
    return request({
        url: '/gw/cust/user/register',
        method: 'post',
        data: data
    })
}

export function login(data) {
    return request({
        url: '/gw/info/login',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

export function login2(data) {
    return request({
        url: '/gw/info/login',
        method: 'post',
        data: data
    })
}

export function submitInfoCustomer(data) {
    return request({
        url: '/gw/cust/user/submitInfo',
        method: 'post',
        data: data
    })
}

export function getRegion(pid) {
    return request({
        url: `/gw/region/list/${pid}`,
        method: 'get'
    })
}

export function getCustomerCount() {
    return request({
        url: '/gw/bill/customer/count',
        method: 'get'
    })
}

export function getBillCustomer(params) {
    return request({
        url: '/gw/bill/customer/page',
        method: 'get',
        params:params
    })
}

export function downloadExportBill(billCode) {
    return request({
        url: '/gw/bill/export-bill/'+billCode,
        method:'get',
        responseType:"blob"
    })
}

export function downloadExportBatchBill(params) {
    return request({
        url: '/gw/bill/export-bill-batch',
        method:'get',
        params:params,
        responseType:"blob"
    })
}

export function getBillCustomerByBillCode(billCode) {
    return request({
        url: '/gw/bill/customer/'+billCode,
        method:'get',
    })
}

export function getOrderCount() {
    return request({
        url: '/gw/order/count',
        method: 'get'
    })
}

export function getOrderPage(params) {
    return request({
        url: '/gw/order/page',
        method: 'post',
        data:params
    })
}

export function downloadImportTemplate(params) {
    return request({
        url: '/gw/order/import_template',
        method:'get',
        params:params,
        responseType:"blob"
    })
}

export function selectFBA(params) {
    return request({
        url: '/gw/warehouse/selectFBA',
        method: 'get',
        params: params
    })
}

export function selecttransit(query) {
    return request({
        url: '/gw/warehouse/selecttransit',
        method: 'get',
        params: query
    })
}

export function getCountryList(params) {
    return request({
        url: '/gw/country/list',
        method: 'get',
        params: params
    })
}

export function getChannelPage(query) {
    return request({
        url: '/gw/channel/page',
        method: 'get',
        params: query
    })
}

export function addWebsiteOrder(data) {
    return request({
        url: '/gw/order',
        method: 'post',
        data: data
    })
}

export function getOrderByOrderCode(orderCode) {
    return request({
        url: '/gw/order/'+orderCode,
        method:'get',
    })
}

export function getArticleQuery(query) {
    return request({
        url: '/gw/content/article/page',
        headers: {
            isToken: false
        },
        method: 'get',
        params: query
    })
}

export function getCustomsCodeList(data) {
    return request({
        url: '/gw/content/customs-code/list',
        method: 'get',
        params: data
    })
}

export function getWarehouseAddressList(data) {
    return request({
        url: '/gw/content/amazon-warehouse-address/list',
        method: 'get',
        params: data
    })
}

export function getAntiDumpingList(data) {
    return request({
        url: '/gw/content/anti-dumping/list',
        method: 'get',
        params: data
    })
}

export function getRemoteAreaList(data) {
    return request({
        url: '/gw/content/remote-area/list',
        method: 'get',
        params: data
    })
}

export function getBasePortList(query) {
    return request({
        url: '/gw/content/basePort/list',
        method: 'get',
        params: query
    })
}

export function getResearchReportList(query) {
    return request({
        url: '/gw/content/researchReport/page',
        method: 'get',
        params: query
    })
}

export function getCustUserList(query) {
    return request({
        url: '/gw/cust/user/list',
        method: 'get',
        params: query
    })
}

export function addCustUser(data) {
    return request({
        url: '/gw/cust/user/add',
        method: 'post',
        data: data
    })
}

export function editCustUser(data) {
    return request({
        url: '/gw/cust/user/edit',
        method: 'post',
        data: data
    })
}

export function getUserInfo(query) {
    return request({
        url: '/gw/info/getUserInfo',
        method: 'get'
    })
}

export function getRoleList(query) {
    return request({
        url: '/gw/cust/role/listAll',
        method: 'get',
        params: query
    })
}

export function addCustRole(data) {
    return request({
        url: '/gw/cust/role/add',
        method: 'post',
        data: data
    })
}

export function editCustRole(data) {
    return request({
        url: '/gw/cust/role/edit',
        method: 'post',
        data: data
    })
}

export function updatePwd(data) {
    return request({
        url: '/gw/info/updatePwd',
        method: 'post',
        data: data
    })
}

export function getUserDetailById(id) {
    return request({
        url: '/gw/cust/user/detail/'+id,
        method: 'get'
    })
}

export function getCustomerStateSum(query) {
    return request({
        url: '/gw/order/customer/state_sum',
        method: 'get',
        params: query
    })
}

export function getCustomerInfo(query) {
    return request({
        url: '/gw/info/getCustomerInfo',
        method: 'get',
        params: query
    })
}

export function getSaleInfoById(id) {
    return request({
        url: '/gw/sys/user/getSaleInfo/'+id,
        method: 'get'
    })
}

export function getCustomerLast(query) {
    return request({
        url: '/gw/order/customer/last',
        method: 'get',
        params: query
    })
}

export function getOrderSum(query) {
    return request({
        url: '/gw/order/customer/sum',
        method: 'get',
        params: query
    })
}

export function getChannelCountry(query) {
    return request({
        url: '/gw/channel/channel_country',
        method: 'get',
        params: query
    })
}

export function getChannelList(query) {
    return request({
        url: '/gw/channel/page',
        method: 'get',
        params: query
    })
}

export function getAllCountryList(params) {
    return request({
        url: '/gw/country/list',
        method: 'get',
        params: params
    })
}

export function getQuotationEnquiry(params) {
    return request({
        url: '/gw/quotation/enquiry',
        method: 'get',
        params: params
    })
}

export function getQuotationDeliveryList(params) {
    return request({
        url: '/gw/quotation/delivery_warehouse/list',
        method: 'get',
        params: params
    })
}

export function getCustomerOrderInfoByCode(code) {
    return request({
        url: '/gw/order/customer/orderInfo/'+code,
        method: 'get'
    })
}

export function downloadTaxes(orderCode) {
    return request({
        url: '/gw/order/download_taxes/'+orderCode,
        method:'get',
        responseType:"blob"
    })
}

export function downloadDeliveryData(params) {
    return request({
        url: '/gw/order/export/deliveryData',
        method:'post',
        data:params,
        responseType:"blob"
    })
}

export function downloadExportBatch(params) {
    return request({
        url: '/gw/order/warehousing/exportBatch?orderCodeSet='+params.orderCodeSet,
        method:'get',
        responseType:"blob"
    })
}

export function cancelOrderPls(orderCode) {
    return request({
        url: '/gw/order/cancel/'+orderCode,
        method: 'post'
    })
}

export function downloadOrderExport(params) {
    return request({
        url: '/gw/order/export',
        method:'post',
        data:params,
        responseType:"blob"
    })
}

export function downloadOrderRoute(params) {
    return request({
        url: '/gw/order/export/orderRoute',
        method:'post',
        data:params,
        responseType:"blob"
    })
}

export function getOrderByCode(orderCode) {
    return request({
        url: '/gw/order/'+orderCode,
        method: 'get'
    })
}

export function getOrderByCode2(orderCode) {
    return request({
        url: '/gw/order/customer/route/list/'+orderCode,
        method: 'get'
    })
}

export function editWebsiteOrder(id,data) {
    return request({
        url: '/gw/order/'+id,
        method: 'patch',
        data:data
    })
}

export function getPrintShippingMark(params) {
    return request({
        url: '/gw/order/printShippingMark',
        method: 'get',
        params: params
    })
}

export function getRoleById(roleId) {
    return request({
        url: '/gw/cust/role/'+roleId,
        method: 'get'
    })
}

export function downloadGoodsTemplate(orderCode) {
    return request({
        url: '/gw/order/goods/import_template/'+orderCode,
        method:'get',
        responseType:"blob"
    })
}

export function getGoodsListByOrderCode(orderCode) {
    return request({
        url: '/gw/order/goods/list/'+orderCode,
        method:'get'
    })
}

export function downloadExportHol(params) {
    return request({
        url: '/gw/order/exportHol',
        method:'get',
        params:params,
        responseType:"blob"
    })
}

export function getOwPage() {
    return request({
        url: '/gw/owPage/list',
        method: 'get'
    })
}

export function getOpenApi() {
    return request({
        url: '/gw/open_api/getOpenApi',
        method: 'get'
    })
}

export function saveApi(data) {
    return request({
        url: '/gw/open_api/api_user_name',
        method:'put',
        params:data,
    })
}

export function refreshApi() {
    return request({
        url: '/gw/open_api/secret/refresh',
        method:'put'
    })
}

export function downloadShippingMark(params) {
    return request({
        url: '/gw/order/exportShippingMark',
        method:'get',
        params:params,
        responseType:"blob"
    })
}

export function uploadFile(data) {
    return request({
        url: '/gw/common/upload',
        method: 'post',
        data: data
    })
}

export function getOrderFclPage(params) {
    return request({
        url: '/gw/order/fcl/page',
        method: 'post',
        data: params
    })
}

export function forgetPwd(data) {
    return request({
        url: '/gw/info/forgetPwd',
        method: 'post',
        data: data
    })
}

export function getTranslate(data) {
    return request({
        url: '/gw/translate',
        method: 'post',
        data: data
    })
}

export function getContentCountryList() {
    return request({
        url: '/gw/content/basePort/countryList',
        method: 'get'
    })
}

export function getContinentList() {
    return request({
        url: '/gw/content/country/continentList',
        method: 'get'
    })
}

export function getMoreLinkOrderPage(params) {
    return request({
        url: '/gw/order/morelink/page',
        method: 'post',
        data: params
    })
}

export function getOrderMoreLinkBySono(sono) {
    return request({
        url: '/gw/order/morelink/'+sono,
        method:'get'
    })
}